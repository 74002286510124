import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from '../../components/Sidebar';
import { Button, TextField, Toolbar } from '@mui/material';
import axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const PageTransactions = () => {
	const [list, setList] = useState<any[]>([]);
	const [open, setOpen] = useState(false);
	const [type, setType] = useState('create');
	const [editID, setEditID] = useState('');
	const navigate = useNavigate();
	const [cookies, setCookies] = useCookies(['user']);

	if (!cookies.user?.accessToken) navigate('/auth');

	const handleOpen = async (type: string = 'create', ID: string = '') => {
		setOpen(true);
		setType(type);
		setEditID(ID);

		// if (type === 'edit') {
		// 	const response = await fetchData(ID);

		// 	if (!response.error) {
		// 		(document.getElementById('field_username') as HTMLInputElement).value =
		// 			response.data.username;
		// 		(document.getElementById('field_email') as HTMLInputElement).value =
		// 			response.data.email;

		// 		(document.getElementById('field_firstName') as HTMLInputElement).value =
		// 			response.data.firstName;
		// 		(document.getElementById('field_lastName') as HTMLInputElement).value =
		// 			response.data.lastName;
		// 	}
		// }
	};
	const handleClose = () => setOpen(false);

	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 700,
		bgcolor: 'background.paper',
		border: 'none',
		boxShadow: 24,
		p: 4,
	};

	const fetchData = async (id: string | null = null) => {
		let response = await axios
			.get(
				`https://api.thebetacompany.uz/tbc-billing-beon/transactions/${
					id ? id : ''
				}`,
				{
					headers: {
						Authorization: `Bearer ${cookies.user?.accessToken}`,
					},
				}
			)
			.then(res => res.data)
			.catch(error => error.response.data);

		if (id) return response;

		let rows: {
			id: any;
			_id: any;
			merchant: {
				name: any;
				tin: any;
				bic: any;
				account: any;
			};
			sum: any;
			clientPhone: any;
			date: any;
		}[] = [];
		response.data.forEach((item: any, index: any) => {
			rows.push({
				id: index,
				_id: item._id,
				merchant: {
					name: item.merchant.name,
					tin: item.merchant.tin,
					bic: item.merchant.bic,
					account: item.merchant.account,
				},
				sum: item.sum,
				clientPhone: item.clientPhone,
				date: item.date,
			});
		});

		setList(rows);
	};

	const submitInformation = async (type: string = 'create') => {
		const data = {
			tin: (document.getElementById('field_tin') as HTMLInputElement).value,
			sum: (document.getElementById('field_sum') as HTMLInputElement).value,
			clientPhone: (
				document.getElementById('field_clientPhone') as HTMLInputElement
			).value,
		};

		const response = await axios({
			method: type === 'create' ? 'post' : 'patch',
			url: `https://api.thebetacompany.uz/tbc-billing-beon/transactions/${
				editID && type === 'edit' ? editID : ''
			}`,
			data: data,
			headers: {
				Authorization: `Bearer ${cookies.user?.accessToken}`,
			},
		})
			.then(res => res.data)
			.catch(res => res.response.data);

		if (response.error) {
			(
				document.getElementById('errorTextMessage') as HTMLInputElement
			).style.color = 'red';
			(
				document.getElementById('errorTextMessage') as HTMLInputElement
			).innerText = response.description || '';
		} else {
			(
				document.getElementById('errorTextMessage') as HTMLInputElement
			).style.color = 'green';
			(
				document.getElementById('errorTextMessage') as HTMLInputElement
			).innerText = response.description || '';
		}
	};

	useEffect(() => {
		if (list.length < 1) fetchData();
	});

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<Sidebar />
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					p: 3,
					width: { sm: `calc(100% - ${240}px)` },
				}}
			>
				<Toolbar>
					<h2>Transactions</h2>
				</Toolbar>
				<Toolbar style={{ justifyContent: 'flex-end' }}>
					<Button variant='contained' onClick={() => handleOpen('create')}>
						Add <AddRoundedIcon />
					</Button>
				</Toolbar>
				<div style={{ height: 800, width: '100%' }}>
					{list && (
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label='simple table'>
								<TableHead>
									<TableRow>
										<TableCell align='center' colSpan={4}>
											Merchant Information
										</TableCell>
										<TableCell align='center' colSpan={3}>
											Transaction Information
										</TableCell>
										<TableCell align='right'>#</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>TIN</TableCell>
										<TableCell>BIC</TableCell>
										<TableCell>Account</TableCell>
										<TableCell>Client phone</TableCell>
										<TableCell>Sum</TableCell>
										<TableCell>Date</TableCell>
										<TableCell align='right'>Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{list.map(row => (
										<TableRow
											key={row._id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component='th' scope='row'>
												{row.merchant.name}
											</TableCell>
											<TableCell>{row.merchant.tin}</TableCell>
											<TableCell>{row.merchant.bic}</TableCell>
											<TableCell>{row.merchant.account}</TableCell>
											<TableCell>{row.clientPhone}</TableCell>
											<TableCell>{row.sum / 100}</TableCell>
											<TableCell>{new Date(row.date).toString()}</TableCell>
											<TableCell align='right'>
												<Button disabled>
													<EditRoundedIcon />
												</Button>
												<Button disabled color='error'>
													<DeleteOutlineRoundedIcon />
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</div>
			</Box>

			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Typography id='transition-modal-title' variant='h6' component='h2'>
							{type === 'create' ? 'Add Transaction' : 'Edit Transaction'}
						</Typography>

						<div
							style={{
								marginTop: '20px',
								display: 'flex',
								justifyContent: 'space-between',
								gap: '10px',
							}}
						>
							<TextField
								id='field_tin'
								label='Merchant TIN'
								variant='outlined'
								fullWidth={true}
								focused
							/>
							<TextField
								id='field_sum'
								label='Sum (Tiins) Ex. 1s = 100t'
								variant='outlined'
								fullWidth={true}
								focused
							/>
							<TextField
								id='field_clientPhone'
								label='Client phone'
								variant='outlined'
								fullWidth={true}
								focused
							/>
						</div>

						<div>
							<span id='errorTextMessage' style={{ color: 'red' }}></span>
						</div>

						<div
							style={{
								marginTop: '30px',
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								variant='contained'
								onClick={() => submitInformation(type)}
							>
								Submit
							</Button>
						</div>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};

export default PageTransactions;
