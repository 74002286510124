import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from '../../components/Sidebar';
import { Button, TextField, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CookiesProvider, useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const PageSettings = () => {
	const [list, setList] = useState<any[]>([]);
	const navigate = useNavigate();
	const [cookies, setCookies] = useCookies(['user']);

	if (!cookies.user?.accessToken) navigate('/auth');

	const fetchData = async (id: string | null = null) => {
		let response = await axios
			.get(
				`https://api.thebetacompany.uz/tbc-billing-beon/configurations/${
					id ? id : ''
				}`,
				{
					headers: {
						Authorization: `Bearer ${cookies.user?.accessToken}`,
					},
				}
			)
			.then(res => res.data)
			.catch(error => error.message);

		if (id) return response;

		let rows: {
			id: any;
			_id: any;
			label: any;
			key: any;
			value: any;
		}[] = [];
		response.data.forEach((item: any, index: any) => {
			rows.push({
				id: index,
				_id: item._id,
				label: item.label,
				key: item.key,
				value: item.value,
			});
		});

		setList(rows);
	};

	const saveSettings = async () => {
		let response;

		list.map(async item => {
			response = await axios
				.patch(
					`https://api.thebetacompany.uz/tbc-billing-beon/configurations/${item._id}`,
					{
						value: (document.getElementById(item.key) as HTMLInputElement)
							.value,
					},
					{
						headers: {
							Authorization: `Bearer ${cookies.user?.accessToken}`,
						},
					}
				)
				.then(res => res.data)
				.catch(error => error.response.data);
		});
	};

	useEffect(() => {
		if (list.length < 1) fetchData();
	});

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<Sidebar />
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					p: 3,
					width: { sm: `calc(100% - ${240}px)` },
				}}
			>
				<Toolbar>
					<h2>Settings</h2>
				</Toolbar>
				<Box>
					<div
						style={{
							marginTop: '20px',
							display: 'flex',
							gap: '10px',
							flexWrap: 'wrap',
						}}
					>
						{list &&
							list.map((item, index) => (
								<TextField
									key={index}
									id={item.key}
									label={item.label}
									defaultValue={item.value}
									variant='outlined'
									focused
								/>
							))}
					</div>
					<div
						style={{
							marginTop: '30px',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<Button variant='contained' onClick={() => saveSettings()}>
							Save
						</Button>
					</div>
				</Box>
			</Box>
		</Box>
	);
};

export default PageSettings;
