import { createBrowserRouter } from 'react-router-dom';
import PageUsers from '../pages/Users';
import PageMerchants from '../pages/Merchants';
import PageTransactions from '../pages/Transactions';
import PageSettings from '../pages/Settings';
import PageAuth from '../pages/Auth';

const Routes = createBrowserRouter([
	{
		path: '/users',
		element: <PageUsers />,
	},
	{
		path: '/merchants',
		element: <PageMerchants />,
	},
	{
		path: '/transactions',
		element: <PageTransactions />,
	},
	{
		path: '/settings',
		element: <PageSettings />,
	},
	{
		path: '/auth',
		element: <PageAuth />,
	},
]);

export default Routes;
