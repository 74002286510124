import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import EvStationRounded from '@mui/icons-material/EvStationRounded';
import AccountBalanceRounded from '@mui/icons-material/AccountBalanceRounded';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import DescriptionRounded from '@mui/icons-material/DescriptionRounded';
import SettingsIcon from '@mui/icons-material/Settings';

const drawerWidth = 240;

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * Remove this when copying and pasting into your project.
	 */
	window?: () => Window;
}

const Sidebar = (props: Props) => {
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [isClosing, setIsClosing] = React.useState(false);

	const handleDrawerClose = () => {
		setIsClosing(true);
		setMobileOpen(false);
	};

	const handleDrawerTransitionEnd = () => {
		setIsClosing(false);
	};

	const handleDrawerToggle = () => {
		if (!isClosing) {
			setMobileOpen(!mobileOpen);
		}
	};

	const navigationItems = [
		{
			name: 'Users',
			url: '/users',
			icon: <AccountCircleRoundedIcon />,
		},
		{
			name: 'Merchants',
			url: '/merchants',
			icon: <EvStationRounded />,
		},
		{
			name: 'Transactions',
			url: '/transactions',
			icon: <ReceiptLongRoundedIcon />,
		},
		// {
		// 	name: 'Bank Documents',
		// 	url: '/bank-documents',
		// 	icon: <AccountBalanceRounded />,
		// },
		// {
		// 	name: 'Didox Documents',
		// 	url: '/didox-documents',
		// 	icon: <DescriptionRounded />,
		// },
		{
			name: 'Settings',
			url: '/settings',
			icon: <SettingsIcon />,
		},
	];

	const drawer = (
		<div>
			<Toolbar />
			<Divider />
			<List>
				{navigationItems.map((item, index) => (
					<Link
						to={item.url}
						key={item.name}
						style={{ textDecoration: 'none', color: 'black' }}
					>
						<ListItem key={item.name} disablePadding>
							<ListItemButton>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText primary={item.name} />
							</ListItemButton>
						</ListItem>
					</Link>
				))}
			</List>
		</div>
	);

	// Remove this const when copying and pasting into your project.
	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<Box
			component='nav'
			sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
			aria-label='mailbox folders'
		>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Drawer
				container={container}
				variant='temporary'
				open={mobileOpen}
				onTransitionEnd={handleDrawerTransitionEnd}
				onClose={handleDrawerClose}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth,
					},
				}}
			>
				{drawer}
			</Drawer>
			<Drawer
				variant='permanent'
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth,
					},
				}}
				open
			>
				{drawer}
			</Drawer>
		</Box>
	);
};

export default Sidebar;
