import { RouterProvider, useNavigate } from 'react-router-dom';
import Routes from './controllers/routes';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useEffect } from 'react';

const App = () => {
	const [cookies, setCookies] = useCookies(['user']);

	const fetchData = async () => {
		const response = await axios
			.get('https://api.thebetacompany.uz/tbc-billing-beon/users', {
				headers: {
					Authorization: `Bearer ${cookies.user?.accessToken}`,
				},
			})
			.then(res => res.data)
			.catch(error => error.response.data);

		if (response.error && response.description.includes('Unauthorized')) {
			setCookies(
				'user',
				{
					accessToken: false,
				},
				{ path: '/' }
			);
		}
	};

	if (!cookies.user?.accessToken && window.location.pathname != '/auth')
		window.location.href = '/auth';

	useEffect(() => {
		fetchData();
	}, []);

	return <RouterProvider router={Routes} />;
};

export default App;
