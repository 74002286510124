import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from '../../components/Sidebar';
import { Button, TextField, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CookiesProvider, useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const PageAuth = () => {
	const navigate = useNavigate();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [cookies, setCookies] = useCookies(['user']);

	if (cookies.user?.accessToken) window.location.href = '/transactions';

	const handleAuth = async () => {
		const response = await axios
			.post('https://api.thebetacompany.uz/tbc-billing-beon/users/authorize', {
				username: username,
				password: password,
			})
			.then(res => res.data)
			.catch(error => error.response.data);

		if (response.error)
			(document.getElementById('error') as HTMLInputElement).innerText =
				response.description;

		setCookies(
			'user',
			{
				accessToken: response.data.accessToken,
			},
			{ path: '/' }
		);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					p: 3,
					justifyContent: 'center',
					width: { sm: `calc(100% - ${240}px)` },
				}}
			>
				<Toolbar
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '100px',
					}}
				>
					<h2>Authorization</h2>
				</Toolbar>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div>
						<TextField
							label={'Username'}
							defaultValue={''}
							onChange={e => setUsername(e.currentTarget.value)}
						/>
					</div>
					<div style={{ marginTop: '25px' }}>
						<TextField
							label={'Password'}
							defaultValue={''}
							type='password'
							onChange={e => setPassword(e.currentTarget.value)}
						/>
					</div>
					<div style={{ marginTop: '25px' }}>
						<Button variant='contained' onClick={handleAuth}>
							Authorize
						</Button>
					</div>
					<div style={{ marginTop: '30px' }}>
						<span id='error' style={{ color: 'red' }}></span>
					</div>
				</Box>
			</Box>
		</Box>
	);
};

export default PageAuth;
