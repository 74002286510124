import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from '../../components/Sidebar';
import { Button, TextField, Toolbar } from '@mui/material';
import axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const PageMerchants = () => {
	const [list, setList] = useState<any[]>([]);
	const [open, setOpen] = useState(false);
	const [type, setType] = useState('create');
	const [editID, setEditID] = useState('');
	const navigate = useNavigate();
	const [cookies, setCookies] = useCookies(['user']);

	if (!cookies.user?.accessToken) navigate('/auth');

	const handleOpen = async (type: string = 'create', ID: string = '') => {
		setOpen(true);
		setType(type);
		setEditID(ID);

		if (type === 'edit') {
			const response = await fetchData(ID);

			if (!response.error) {
				(document.getElementById('field_name') as HTMLInputElement).value =
					response.data.name;
				(document.getElementById('field_tin') as HTMLInputElement).value =
					response.data.tin;
				(document.getElementById('field_bic') as HTMLInputElement).value =
					response.data.bic;
				(document.getElementById('field_account') as HTMLInputElement).value =
					response.data.account;
				(
					document.getElementById('field_serviceFee') as HTMLInputElement
				).value = response.data.serviceFee;
				(
					document.getElementById('field_contractNumber') as HTMLInputElement
				).value = response.data.contractNumber;
				(
					document.getElementById('field_contractDate') as HTMLInputElement
				).value = response.data.contractDate;
			}
		}
	};
	const handleClose = () => setOpen(false);

	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 700,
		bgcolor: 'background.paper',
		border: 'none',
		boxShadow: 24,
		p: 4,
	};

	const fetchData = async (id: string | null = null) => {
		let response = await axios
			.get(
				`https://api.thebetacompany.uz/tbc-billing-beon/merchants/${
					id ? id : ''
				}`,
				{
					headers: {
						Authorization: `Bearer ${cookies.user?.accessToken}`,
					},
				}
			)
			.then(res => res.data)
			.catch(error => error.message);

		if (id) return response;

		let rows: {
			id: any;
			_id: any;
			name: any;
			tin: any;
			bic: any;
			account: any;
			serviceFee: any;
			contractNumber: any;
			contractDate: any;
			balance: any;
		}[] = [];
		response.data.forEach((item: any, index: any) => {
			rows.push({
				id: index,
				_id: item._id,
				name: item.name,
				tin: item.tin,
				bic: item.bic,
				account: item.account,
				serviceFee: item.serviceFee,
				contractNumber: item.contractNumber,
				contractDate: item.contractDate,
				balance: item.balance,
			});
		});

		setList(rows);
	};

	const submitInformation = async (type: string = 'create') => {
		const data = {
			name: (document.getElementById('field_name') as HTMLInputElement).value,
			tin: (document.getElementById('field_tin') as HTMLInputElement).value,
			bic: (document.getElementById('field_bic') as HTMLInputElement).value,
			account: (document.getElementById('field_account') as HTMLInputElement)
				.value,
			serviceFee: (
				document.getElementById('field_serviceFee') as HTMLInputElement
			).value,
			contractNumber: (
				document.getElementById('field_contractNumber') as HTMLInputElement
			).value,
			contractDate: (
				document.getElementById('field_contractDate') as HTMLInputElement
			).value,
		};

		const response = await axios({
			method: type === 'create' ? 'post' : 'patch',
			url: `https://api.thebetacompany.uz/merchants/${
				editID && type === 'edit' ? editID : ''
			}`,
			data: data,
			headers: {
				Authorization: `Bearer ${cookies.user?.accessToken}`,
			},
		})
			.then(res => res.data)
			.catch(res => res.response.data);

		if (response.error) {
			(
				document.getElementById('errorTextMessage') as HTMLInputElement
			).style.color = 'red';
			(
				document.getElementById('errorTextMessage') as HTMLInputElement
			).innerText = response.description || '';
		} else {
			(
				document.getElementById('errorTextMessage') as HTMLInputElement
			).style.color = 'green';
			(
				document.getElementById('errorTextMessage') as HTMLInputElement
			).innerText = response.description || '';
		}
	};

	useEffect(() => {
		if (list.length < 1) fetchData();
	});

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<Sidebar />
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					p: 3,
					width: { sm: `calc(100% - ${240}px)` },
				}}
			>
				<Toolbar>
					<h2>Merchants</h2>
				</Toolbar>
				<Toolbar style={{ justifyContent: 'flex-end' }}>
					<Button variant='contained' onClick={() => handleOpen('create')}>
						Add <AddRoundedIcon />
					</Button>
				</Toolbar>
				<div style={{ height: 800, width: '100%' }}>
					{list && (
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label='simple table'>
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>TIN</TableCell>
										<TableCell>BIC</TableCell>
										<TableCell>Account</TableCell>
										<TableCell>Service Fee</TableCell>
										<TableCell>Contract Number</TableCell>
										<TableCell>Contract Date</TableCell>
										<TableCell>Balance</TableCell>
										<TableCell align='right'>Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{list.map(row => (
										<TableRow
											key={row._id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component='th' scope='row'>
												{row.name}
											</TableCell>
											<TableCell>{row.tin}</TableCell>
											<TableCell>{row.bic}</TableCell>
											<TableCell>{row.account}</TableCell>
											<TableCell>{row.serviceFee + '%'}</TableCell>
											<TableCell>{row.contractNumber}</TableCell>
											<TableCell>{row.contractDate}</TableCell>
											<TableCell>
												<span>{row.balance / 100}</span>
											</TableCell>
											<TableCell align='right'>
												<Button onClick={() => handleOpen('edit', row._id)}>
													<EditRoundedIcon />
												</Button>
												<Button disabled color='error'>
													<DeleteOutlineRoundedIcon />
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</div>
			</Box>

			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Typography id='transition-modal-title' variant='h6' component='h2'>
							{type === 'create' ? 'Add Merchant' : 'Edit Merchant'}
						</Typography>

						<div
							style={{
								marginTop: '20px',
								display: 'flex',
								justifyContent: 'space-between',
								gap: '10px',
							}}
						>
							<TextField
								id='field_name'
								label='Name'
								variant='outlined'
								fullWidth={true}
								focused
							/>
							<TextField
								id='field_tin'
								label='TIN'
								variant='outlined'
								fullWidth={true}
								focused
							/>
						</div>

						<div
							style={{
								marginTop: '20px',
								display: 'flex',
								justifyContent: 'space-between',
								gap: '10px',
							}}
						>
							<TextField
								id='field_bic'
								label='BIC'
								variant='outlined'
								fullWidth={true}
								focused
							/>
							<TextField
								id='field_account'
								label='Account'
								variant='outlined'
								fullWidth={true}
								focused
							/>
						</div>

						<div
							style={{
								marginTop: '20px',
								display: 'flex',
								justifyContent: 'space-between',
								gap: '10px',
							}}
						>
							<TextField
								id='field_serviceFee'
								label='Service Fee'
								variant='outlined'
								fullWidth={true}
								focused
							/>
							<TextField
								id='field_contractNumber'
								label='Contract Number'
								variant='outlined'
								fullWidth={true}
								focused
							/>
							<TextField
								id='field_contractDate'
								label='Contract Date'
								variant='outlined'
								fullWidth={true}
								focused
							/>
						</div>

						<div>
							<span id='errorTextMessage' style={{ color: 'red' }}></span>
						</div>

						<div
							style={{
								marginTop: '30px',
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								variant='contained'
								onClick={() => submitInformation(type)}
							>
								Submit
							</Button>
						</div>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};

export default PageMerchants;
